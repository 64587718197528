<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="$router.go(-1)"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center">THÔNG TIN XE</div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                            /></div
                    ></slot>
                </div>
            </ion-toolbar>
        </ion-header>
        <ion-content :scroll-events="false">
            <ThongTinXeVue
                :action="action"
                ref="ThongTinPhuongTien"
                @LuuThongTin="LuuThongTin"
            />
        </ion-content>
    </ion-page>
</template>
<script>
import {
    IonHeader,
    IonToolbar,
    IonContent,
    IonPage,
    onIonViewWillLeave,
    onIonViewWillEnter,
} from "@ionic/vue";
import { DxButton } from "devextreme-vue";
import ThongTinXeVue from "./components/ThongTinXe.vue";
export default {
    components: {
        IonHeader,
        IonToolbar,
        IonContent,
        IonPage,
        DxButton,
        ThongTinXeVue,
    },
    data() {
        return {
            action: "Add",
            IdPhuongTien: null,
        };
    },
    computed: {},
    methods: {
        getData() {
            let query = this.$route.query;
            if (query.IdPhuongTien) {
                this.IdPhuongTien = query.IdPhuongTien;
                this.action = "Edit";
                this.layChiTietPhuongTien(query.IdPhuongTien);
            }
            setTimeout(() => {
                this.LayCacDanhSach();
            }, 200);
        },
        async LayCacDanhSach() {
            try {
                await this.$store.dispatch("ThongTinPhuongTien/Get_DanhSachLoaiXe");
                await this.$store.dispatch("ThongTinPhuongTien/Get_DanhSachHangGSHT");
            } catch (ex) {
                console.log("🚀 ~ ex", ex);
            }
        },
        async layChiTietPhuongTien(idPhuongTien) {
            try {
                let rs = await this.$store.dispatch(
                    "ThongTinPhuongTien/Get_ChiTietPhuongTien",
                    idPhuongTien,
                );

                if (rs.status == false) {
                    this.$Helper.ThongBaoPopup({
                        message_title: `Lấy chi tiết phương tiện thất bại!`,
                        message: rs.message,
                    });
                }
            } catch (ex) {
                this.$Helper.ThongBaoPopup({
                    message_title: `Lấy chi tiết phương tiện thất bại!`,
                    message: "Lỗi hệ thống client.",
                });
            }
        },
        async LuuThongTin() {
            try {
                let self = this;
                let rs;
                if (this.action == "Add") {
                    rs = await this.$store.dispatch("ThongTinPhuongTien/ThemPhuongTien");
                } else if (this.action == "Edit") {
                    rs = await this.$store.dispatch(
                        "ThongTinPhuongTien/SuaPhuongTien",
                        self.IdPhuongTien,
                    );
                }
                if (rs.status == true) {
                    this.$Helper.ThongBaoToast(
                        "success",
                        "Lưu thông tin phương tiện thành công!",
                    );
                    if (this.action == "Add") {
                        this.$router.go(-1);
                        return;
                    }
                    this.getData();
                } else {
                    this.$Helper.ThongBaoPopup({
                        message_title: `Lưu thông tin phương tiện thất bại!`,
                        message: rs.message,
                    });
                }
            } catch (ex) {
                console.log("🚀 ~ ex", ex);
                this.$Helper.ThongBaoPopup({
                    message_title: `Lưu thông tin phương tiện thất bại!`,
                    message: "Lỗi hệ thống client.",
                });
            }
        },
    },
    created() {
        onIonViewWillEnter(() => {
            this.getData();
        });
    },
    async mounted() {
        onIonViewWillLeave(() => {
            this.$store.dispatch("ThongTinPhuongTien/ResetThongTinPhuongTien");
        });
    },
    watch: {},
};
</script>
